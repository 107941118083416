<template lang="html">
  <div class="bg">
    <slot />
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  setup() {},
});
</script>
<style lang="scss">
.bg {
  background: #f0f1f7;
}
</style>
